import {inject, Injectable} from '@angular/core';
import {
  Auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInAnonymously,
  User,
  signOut, sendEmailVerification, signInWithCustomToken, idToken,
} from "@angular/fire/auth";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import { IdTokenResult, ActionCodeSettings } from "firebase/auth";
import {first} from "rxjs/operators";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

/* role:
undefined => before init
null => init but user isn't connected
anonymous => anonymous user
pre-registration
reception
platform
business-hub
 */

export class AuthService {
  public isInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuth$:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public role$: BehaviorSubject<undefined | null | 'anonymous' | 'pre-registration' | 'reception' | 'platform' | 'admin'> = new BehaviorSubject<undefined | null | 'anonymous' | 'pre-registration' | 'reception' | 'platform' | 'admin'>(undefined);
  public shopIdList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  private _snackBar = inject(MatSnackBar);

  constructor(private auth: Auth) {

    onAuthStateChanged(this.auth, async (user: User | null) => {
      if (user) {
        this.user$.next(user);
        user.getIdTokenResult(true).then((idTokenResult: IdTokenResult): void => {
          console.log('customClaims');
          console.log(idTokenResult.claims)
          let role: string = idTokenResult.claims['role'] as string;
          this.shopIdList$.next(idTokenResult.claims['shops'] as string[] || []);

          console.log('role: '+role+' shopList: '+this.shopIdList$.value)
          if(['pre-registration' , 'reception' , 'platform' , 'admin'].includes(role)){
            localStorage.setItem('ROLE', role);
            this.role$.next(role as any);
            user.displayName? this.openSnackBar(user.displayName, 'fermer'): null;
          }
          if(user.isAnonymous){
            localStorage.setItem('ROLE', 'anonymous');
            this.role$.next('anonymous');
          }
          this.isAuth$.next(true);
          this.isInit$.next(true);
        });
      } else {
        this.isAuth$.next(false);
        this.user$.next(null);
        this.role$.next(null);
        localStorage.removeItem('ROLE');
        this.isInit$.next(true);
      }
    });
  }

  public async waitForInit(): Promise<void>{
    if(this.isInit$.value){
      return // already init
    }
    await firstValueFrom(this.isInit$.pipe(first(isInit => isInit)))
  }

  public async signInWithEmail(email: string, password: string): Promise<void | { user: User; error: null; }> {
    try {
      return signInWithEmailAndPassword(this.auth, email, password).then(userCredential => {
        // redirect logic should be called here
        return {user: userCredential.user, error: null};
      }).catch((error: any): void => {
       throw error;
      });
    } catch(error: any){
      throw error
    }
  }

  public async signOut(): Promise<void> {
    // redirect logic should be called here
    await signOut(this.auth);
    console.log(this.role$.value);
  }

  public async anonymouslySignIn():  Promise<{user: User | null, error: any}> {
    return signInAnonymously(this.auth).then(userCredential => {
      return {user: userCredential.user, error: null};
    }).catch((error) => {
      return {user: null, error: error.code};
    });
  }

  public sendEmailVerification(user: User, actionCodeSettings: ActionCodeSettings): Promise<void> {
    return sendEmailVerification(user, actionCodeSettings)
  }

  public async signInWithCustomToken(token: string): Promise<{user: User | null, error: any}> {
    return signInWithCustomToken(this.auth, token).then(async userCredential => {
      const idTokenResult = await userCredential.user.getIdTokenResult();
      console.log('Custom Claims:', idTokenResult.claims);
      return {user: userCredential.user, error: null};
    }).catch((error) => {
      return {user: null, error: error.code};
    });
  }

  public async refreshToken() {
    /** this function is used to refresh user token and update custom claims, it does not trigger onAuthStateChange **/
    const user: User | null = this.auth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult(true);
      const role = idTokenResult.claims['role'] as string
      localStorage.setItem('ROLE', role);
      this.role$.next(role as any);
      this.shopIdList$.next(idTokenResult.claims['shops'] as string[]);
    }
  }

  private openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
